<template>
  <div class="theory-container">
    <div class="theory-container-header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>数据中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/trainadmin/datacenter/studenttheoryList',query:{train_id:train_id}}">理论榜单</el-breadcrumb-item>
        <el-breadcrumb-item>班级详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="theory-container-student">
      <span class="student-title">学生: {{ tableData.user_name }}</span>
    </div>
    <el-table
            :data="tableData.list"
            border
            height="100%"
            style="width: 100%; margin-top: 15px"
            :cell-style="{ color: 'rgba(52, 52, 65, 1)', borderRight: 'unset' }"
            :header-cell-style="{
          color: 'rgba(19, 19, 27, 1)',
          background: '#F3F4FA',
          borderRight: 'unset',
        }"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item class="left-one">
              <div
                      v-for="(tItems, tindex) in props.row.children"
                      :key="tindex"
              >
                <el-button
                        @click="goToDetail(props.row,tItems)"
                        type="text"
                        size="small"
                >查看试卷</el-button
                >
              </div>
            </el-form-item>
            <el-form-item class="left-two">
              <div
                      v-for="(tItems, tindex) in props.row.children"
                      :key="tindex"
              >
                <span>{{ tItems.process_rate }}%</span>
              </div>
            </el-form-item>
            <el-form-item class="left-three">
              <div v-for="(items, index) in props.row.children" :key="index">
                  <span class="iconfont"
                  >&#xe665;{{ items.process_index }}/{{
                      items.process_page_total
                    }}训练</span
                  >
              </div>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="训练次数" prop="times_id" align="center">
        <template slot-scope="scope">
          <span>第{{ scope.row.times_key }}次训练</span>
        </template>
      </el-table-column>
      <el-table-column label="正确率" prop="true_rate" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.true_rate }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center"> </el-table-column>
    </el-table>
    <el-pagination
            class="pages-center"
            :current-page="listPages.currentPageNum"
            :page-size="listPages.eachPageNum"
            layout="prev, pager, next, jumper"
            :total="listPages.total"
            @current-change="pageCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { showStudentAnswerListData } from "@/utils/apis";
export default {
  data() {
    return {
      studentName: "",
      tableData: [],
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      train_id:this.$route.query.train_id || null,
      user_id:this.$route.query.user_id || null,
    };
  },
  mounted() {
    this.showStudentAnswerList();
  },
  methods: {
    showStudentAnswerList() {
      let params = {
        paging: "1",
        pageSize: this.listPages.eachPageNum,
        page: this.listPages.currentPageNum,
        user_id: this.user_id,
        train_id: this.train_id,
      };
      showStudentAnswerListData(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.studentName = res.data.user_name;
          this.listPages.total = res.data.total;
        } else {
          this.$message({
            type: "warming",
            message: res.msg,
          });
        }
      });
    },
    // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.showStudentAnswerList();
    },

    // 查看详情
    goToDetail(row,tItems) {
      this.$router.push({
        path:'/trainadmin/datacenter/studentanswesr',
        query:{
          train_id: row.train_id,
          times_id: row.times_id,
          process_index:tItems.process_index,
          process_page_total:tItems.process_page_total,
          studentName:this.studentName,
          user_id:this.user_id,
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.theory-container {
  ::v-deep .el-button--text {
    color: rgba(17, 34, 216, 1);
  }
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .theory-container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .training-main {
      .training-title {
        margin-left: 4px;
        font-weight: bold;
      }
    }
    ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
      color: rgb(0, 0, 0);
      font-weight: bold;
    }
    ::v-deep .el-breadcrumb__item {
      .el-breadcrumb__inner.is-link {
        font-weight: 400;
        color: #606266;
      }
    }
  }
  .theory-container-student {
    margin-top: 26px;
    ::v-deep .el-table th.el-table__cell {
      background: rgba(245, 245, 251, 1);
    }
    .pages-center {
      margin-top: 15px;
      text-align: center;
    }
    .student-title {
      font-weight: bold;
    }
  }
  .el-table {
    flex: 1;
    .left-one {
      position: relative;
      left: 82.5% !important;
    }
    .left-two {
      position: relative;
      left: 47% !important;
    }
    .left-three {
      position: relative;
      left: 12% !important;
    }
  }
  .pages-center {
    margin-top: 15px;
    text-align: center;
  }
}
</style>